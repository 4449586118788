import PropTypes from 'prop-types';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Frame from '../images/FrameForbidden.svg';
import Logo from '../images/logo.png';
import styles from './unauthorizedUser.module.css';

function goBackToIndex(redirectUrl, appId) {
  window.location.href = `/?redirect_url=${redirectUrl}&appId=${appId}`;
}

function unauthorizedUser(props) {
  const searchParams = new URLSearchParams(props.location.search);
  const redirectUrl = searchParams.get('redirect_url');
  const appId = searchParams.get('appId');

  return (
    <div className={styles.root}>
      <Layout>
        <SEO />
        <div className={styles.header}>
          <img
            rel="icon"
            className={styles.header__logo}
            alt="logo-sso"
            src={Logo}
          />
          <p className={styles.header__title}>Wizbii Tools</p>
          <p className={styles.header__subtitle}>Portail de connexion</p>
        </div>
        <div className={styles.box}>
          <p className={styles.box__title}>
            Vous n&apos;avez pas accès à ce back Office&nbsp;!
          </p>
          <p className={styles.box__subtitle}>
            veuillez vous connecter avec votre compte wizbii
          </p>
          <button
            className={styles.box__button}
            onClick={() => goBackToIndex(redirectUrl, appId)}
            type="button"
          >
            se déconnecter
          </button>
        </div>
        <div className={styles.frame}>
          <img src={Frame}></img>
        </div>
        <span className={styles.rectangle}></span>
      </Layout>
    </div>
  );
}
goBackToIndex.propTypes = {
  location: PropTypes.object,
};
unauthorizedUser.propTypes = {
  location: PropTypes.object,
};

export default unauthorizedUser;
